<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="card-title pt-3 px-3 d-flex justify-content-between">
            <div class="breadcrumb-left">
              <h4>
                <router-link :to="{ name: 'album-list' }" class="btn btn-standard">
                  <i class="fas fa-arrow-left"></i>
                </router-link>
                Manage Photos
              </h4>
              <div class="breadcrumb-sub-header">
                <router-link to="/dashboard">Dashboard </router-link>\
                <router-link :to="{ name: 'album-list' }"> Gallery </router-link> \ Images
              </div>
            </div>
            <div class="breadcrumb-right">
              <div class="card-toolbar">
                <v-btn
                  @click="addPhoto()"
                  class="mt-1 btn btn-primary"
                  style="color: #fff"
                >
                  <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp; Add
                  Photo
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">          
          <draggable
            @change="sort"
            v-model="values"
            tag="div"
            class="row"
            v-if="values.length > 0"
          >
            <div class="col-3" v-for="(value, index) of values" :key="index">
              <div class="colleges-gallery-card cursor-pointer">
                <img :src="value.image_path['thumb']" alt class="img-thumbnail" />
                <button
                  @click="deletePhoto(value.id)"
                  class="kist-btn-delete"
                  title="Delete"
                >
                  <i class="fas fa-trash"></i>
                </button>

                <button
                  title="feature image"
                  @click="toogleFeaturedPhoto(value)"
                  class="college-btn-feature"
                >
                  <i class="fas fa-images"></i>
                </button>
                <button
                  title="feature image"
                  v-if="value.is_featured"
                  class="college-btn-feature-alt"
                >
                  <i class="fas fa-images"></i>
                  <span class="p-3">Featured</span>
                </button>
              </div>
            </div>
          </draggable>
          <div class="text-center" v-else>
            <h3>Data not available</h3>
          </div>
        </div>
      </div>
    </div>
    <create-and-update
      :album_id="album_id"
      ref="add-photo"
      @refresh_photos="getValues"
    ></create-and-update>
    <overlay :status="overlay_status"></overlay>
  </div>
</template>
<script>
import AlbumValueService from "@/core/services/album/value/AlbumValueService";
import CreateAndUpdate from "./CreateAndUpdate";
import Overlay from "@/view/pages/overlay/Index";
import draggable from "vuedraggable";

const valueService = new AlbumValueService();
export default {
  components: {
    draggable,
    CreateAndUpdate,
    Overlay,
  },
  data() {
    return {
      values: [],
      overlay_status: false,
    };
  },

  computed: {
    album_id() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.getValues();
  },
  methods: {
    getValues() {
      valueService.paginate(this.album_id).then((response) => {
        this.values = response.data;
      });
    },
    addPhoto() {
      this.$refs["add-photo"].showModal();
    },
    sort() {
      valueService
        .sort(this.album_id, this.values)
        .then((res) => {
          this.$snotify.success("Sorted");
          this.getValues();
        })
        .catch((err) => {
          this.$snotify.success("Sorted Error");
        });
    },
    deletePhoto(id) {
      this.overlay_status = true;
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            valueService.delete(this.album_id, id).then((response) => {
              this.$snotify.success("Information deleted");
              this.overlay_status = false;
              this.getValues();
            });
          }
          this.overlay_status = false;
        },
      });
    },
    toogleFeaturedPhoto(value) {
      let data = value;
      data.is_featured = !data.is_featured;
      delete data.image_path;
      valueService.update(this.album_id, data.id, data).then((response) => {
        this.getValues();
      });
    },
  },
};
</script>
<style scoped>
.colleges-gallery-card {
  position: relative;
}

.colleges-gallery-card .kist-btn-delete,
.colleges-gallery-card .college-btn-feature,
.colleges-gallery-card .college-btn-feature-alt {
  position: absolute;
  right: 8px;
  display: inline-block;
  padding: 3px 0;
  border-radius: 5px;
  border: none;
  min-width: 28px;
}

.colleges-gallery-card .kist-btn-delete i,
.colleges-gallery-card .college-btn-feature i,
.colleges-gallery-card .college-btn-feature-alt * {
  font-size: 12px;
}

.colleges-gallery-card .kist-btn-delete {
  top: 10px;
  background-color: #cc0000;
}

.colleges-gallery-card .kist-btn-delete i {
  color: #fff;
}

.colleges-gallery-card .college-btn-feature,
.colleges-gallery-card .college-btn-feature-alt {
  top: 40px;
  background-color: #004c98;
}

.colleges-gallery-card .college-btn-feature-alt {
  padding: 3px 5px;
  top: 10px;
  left: 8px;
  right: auto;
}

.colleges-gallery-card .college-btn-feature i,
.colleges-gallery-card .college-btn-feature-alt * {
  color: #fff;
}
</style>
