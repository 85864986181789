<template>
  <div>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            {{ `${edit ? "Update" : "Add"} Image` }}
            <hr />
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon @click="resetForm">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <div class="row">
            <div class="col-12">
              <v-file-input
                prepend-icon=""
                dense
                outlined
                prepend-inner-icon="mdi-file"
                multiple
                v-model="files"
                @change="checkSize($event)"
                accept=".jpg, .png, .gif"
                placeholder="Image"
                label="Image"
              ></v-file-input>
              <!--                    <b-form-file multiple v-model="files" @change="checkSize($event)" accept=".jpg, .png, .gif"
                                 placeholder="Image"></b-form-file>-->
              <span class="text-danger" v-if="$v.files.$error">Image is required.</span>
              <span class="text-danger" v-if="sizeExceed">File Size Exceed.</span>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="btn btn-standard cancel-btn" depressed @click="hideModal"
            >Cancel
          </v-btn>
          <v-btn
            class="text-white ml-2 btn btn-primary"
            depressed
            :disabled="sizeExceed"
            @click="createOrUpdate"
            :loading="isBusy"
            >Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import AlbumValueService from "@/core/services/album/value/AlbumValueService";

const valueService = new AlbumValueService();
export default {
  props: ["album_id"],
  validations: {
    files: { required },
  },
  data() {
    return {
      edit: false,
      isBusy: false,
      sizeExceed: false,
      files: [],
      value: {
        path: null,
      },
      dialog: false,
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    showModal(id = null) {
      this.openDialog();
    },
    hideModal() {
      this.dialog = false;
    },
    toggleModal() {
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    getAlbum(id) {
      albumService.show(id).then((response) => {
        this.album = response.data.album;
      });
    },
    checkSize(file) {
      let size = file.size / 1024 / 1024;
      let mbSize = size.toFixed(2);
      if (mbSize > 20) {
        this.sizeExceed = true;
      } else {
        this.sizeExceed = false;
      }
    },
    generateFd() {
      let fd = new FormData();
      for (let key in this.files) {
        fd.append("path[]", this.files[key]);
      }
      return fd;
    },
    createOrUpdate() {
      this.$v.files.$touch();
      if (this.$v.files.$error) {
        setTimeout(() => {
          this.$v.files.$reset();
        }, 3000);
      } else {
        let fd = this.generateFd();
        this.addPhoto(fd);
      }
    },
    addPhoto(fd) {
      // console.log(fd)
      this.isBusy = true;
      valueService
        .store(this.album_id, fd)
        .then((response) => {
          this.isBusy = false;

          this.$snotify.success("Information updated");
          this.resetForm();
        })
        .catch((error) => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
          // console.log(error);
        });
    },
    resetForm() {
      this.edit = false;
      this.album = {
        title: null,
        is_active: null,
      };
      this.value = {
        path: null,
      };
      this.files = [];
      this.hideModal();
      this.$emit("refresh_photos");
    },
  },
};
</script>
