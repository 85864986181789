import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class AlbumValueService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/album/`;

      }

      paginate(id, type = null) {
            let url = `${this.#api}${id}/value`;
            if (type)
                  url = url + '?type=' + type;
            return apiService.get(url);
      }

      update(id,valueId,  data) {
            let url = `${this.#api}${id}/value/${valueId}/update`
            return apiService.post(url, data);

      }

      store(id, data) {
            let url = `${this.#api}${id}/value`;
            return apiService.post(url, data);

      }
      sort(id, data) {
            let url = `${this.#api}${id}/value/sort`;
            return apiService.post(url, data);
      }

      show(id) {
            let url = `${this.#api}${id}/value/${id}`
            return apiService.get(url);
      }

      delete(albumId, id) {
            let url = `${this.#api}${albumId}/value/${id}`
            return apiService.delete(url);
      }
}
